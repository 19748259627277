.Navbar-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* font-family: satoshi; */
}
.Navbar-Main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 20px 0px;
  /* max-width: 1344px; */
  /* background-color: red; */
}
.Logo {
  /* width: 100%; */
  max-width: 121px;
  cursor: pointer;
}
.Links-Main {
  width: 100%;
  /* max-width: 920px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Links-Main-Mobile {
  width: 100%;
  /* max-width: 920px; */
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.links {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 40px;
  /* cursor: pointer; */
  /* background-color: #aa0506; */
}
.links span {
  cursor: pointer;
}
.links-mobile {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 20px;
  width: 95%;
  justify-content: space-between;
}
.links-mobile span {
  cursor: pointer;
}
.Button-Main {
  display: flex;
  /* width: 100%; */
  /* max-width: 360px; */
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  gap: 20px;
}
.Login-Btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.Button-Reg {
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px;
  background: linear-gradient(45deg, #f81d1e, #aa0506);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.color-gradient {
  background: linear-gradient(45deg, #f81d1e, #aa0506);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Navbar-center a {
  text-decoration: none;
  color: black;
  padding: 0%;
  line-height: normal;
}
@media (max-width: 1132px) {
  .links {
    font-size: 14px;
  }
  .Links-Main {
    justify-content: end;
  }
}
@media (max-width: 1050px) {
  .Links-Main-Mobile {
    display: flex;
    width: 100%;
    justify-content: "ceter";
  }
  .links {
    display: none;
  }
  .seprator {
    display: none;
  }
}
@media (max-width: 786px) {
  .Logo {
    max-width: 70px;
  }
  .Button-Reg {
    font-size: 15px;
    padding: 12px 16px;
  }
  .Login-Btn {
    font-size: 15px;
  }
  .Button-Main {
    gap: 10px;
  }
  .links-mobile span {
    font-size: 15px;
  }
}
@media (max-width: 433px) {
  .Button-Reg {
    font-size: 10px;
    padding: 8px 12px;
  }
  .Login-Btn {
    font-size: 10px;
  }
  .links-mobile span {
    font-size: 10px;
  }
}
