.si-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10vh 0;
  overflow: hidden;
}
.si-main-heading {
  font-size: 48px;
  font-weight: bold;
}
.si-sub {
  width: 95%;
  display: flex;
  /* background-color: black; */
  justify-content: space-evenly;
  align-items: center;
  padding: 0px 5%;
  align-items: stretch;
  flex-direction: row-reverse;
  gap: 10%;
}
.si-sub-left {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  width: 35%;
  align-self: stretch;
  box-sizing: border-box;
}
.si-sub-left-row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}
.si-form-image {
  background-color: #f8e2e2;
  object-position: 0px 50px;
  border-radius: 8px;
  /* object-fit: none; */
  height: 752px;
  aspect-ratio: 1/1;
}
.si-sub-left-payment-options-container {
  width: 100%;
  background-color: white;
  margin: 30px 0px;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 8.4px 8.4px 42px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
/* .si-main-container svg {
  height: 35px;
  width: 35px;
} */
.si-link {
  color: black;
  cursor: pointer;
}
.signinform-container {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.si-sub-left-policy-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.si-sepreator {
  height: 1px;
  width: 100%;
  background-color: #f5f5f7;
}
.socialContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sign-in-buttons {
  font-size: 16px;
  font-weight: 500;
  color: #2e2c34;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: solid;
  border-width: 1;
  border-color: #ebeaed;
  padding: 13px;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
  color: black;
}
.sign-in-buttons img{
  width: 16px;
  height: 16px;
}
.new-user-btn {
  font-size: 15px;
  margin-top: 10px;
}
.new-user-btn a {
  text-decoration: none;
  color: #f81d1e;
}
.remember-me-btn {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.remember-me-btn p{
  font-size: 14px;
  color: #585858;
}
.forgot-password-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: space-between;
}
.forgot-password-container p {
  font-size: 15px;
}
.forgot-password-container p a {
  text-decoration: none;
  color: #f81d1e;
}
.login-button-through-email {
  margin-top: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  will-change: 60%;
  max-width: 35%;
  flex-grow: 1;
}
.separater-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.separater-container p {
  color: black;
  text-align: center;
  margin: 24px;
}
.policy-btn-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
.business-sign-in-btn {
  margin-left: 0 !important;
}
.check-box {
  height: 24px !important;
  width: 24px !important;
  border-color: black;
  border: solid;
  border-radius: 4px;
  padding: 0px;
  cursor: pointer;
  margin-right: 5px;
  box-sizing: border-box;
}
@media (max-width:1400px){
  .si-form-image{
    height: auto;
  }
}
@media (max-width: 1214px) {
  .remember-me-btn p{
    font-size: 12px;
  }
  .si-sub-left {
    margin-right: 5%;
  }
  .si-main-heading {
    font-size: 40px;
    font-weight: 700;
  }
  .policy-btn-container p {
    font-size: 14px;
  }
}

@media (max-width: 1144px) {
  .separater-container p {
    
    margin: 20px;
  }
  .forgot-password-container{
    margin-top: 20px;
  }
  .signinform-container {
    margin-top: 21px;
  }
  .sign-in-buttons img{
    width: 13px;
    height: 13px;
  }
  .sign-in-buttons{
    font-size: 14px;
    padding: 10px;
    border-radius: 6px;
  }
  .check-box {
    height: 16px !important;
    width: 16px !important;
  }
  .si-sub {
    flex-direction: column;
    gap: 100px;
  }
  .socialContainer {
    flex-direction: row;
    gap: 20px;
  }
  .si-sub-left {
    width: 100%;
  }
  .si-form-image {
    width: 100%;
    height: 500px;
    object-fit: contain;
    background-color: #f8e2e2;
    object-position: center 100px;
  }
  .si-sub-left-row-container {
    gap: 10px;
    flex-direction: row;
  }
  .si-sub-left-policy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .si-sub-left-policy-container .Button-Reg {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .new-user-btn {
    font-size: 12px;
  }
  .remember-me-btn p{
    font-size: 10px;
  }
  .forgot-password-container p {
    font-size: 12px;
  }
  .socialContainer{
    gap: 10px;
  }
  .sign-in-buttons img{
    width: 10px;
    height: 10px;
  }
  .sign-in-buttons{
    font-size: 12px;
    padding: 6px;
  }
  .separater-container p {
    
    margin: 14px;
  }
  .forgot-password-container{
    margin-top: 14px;
  }
  .check-box {
    height: 12px !important;
    width: 12px !important;
    border-radius: 4px;
  }
  .si-main-heading {
    font-size: 28px;
    margin: 7.27px;
    font-weight: 700;
  }
  /* .si-main-container svg {
    height: 35px;
    width: 35px;
  } */
  .si-sub-left-policy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .si-sub-left-policy-container .Button-Reg {
    width: 100%;
  }
  /* .socialContainer {
    flex-direction: column;
  } */
  .policy-btn-container p {
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .signinform-container {
    margin-top: 14px;
  }
}

@media (max-width: 370px){
  .sign-in-buttons{
    font-size: 10px;
    padding: 4px;
  }
  .su-sub-left-policy-text{
    font-size: 10px;
  }
}