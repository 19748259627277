.d-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10vh 0;
  overflow: hidden;
}
.d-sub-container {
  width: 95%;
  background-color: #f81d1e;
  background-image: url("../../../public/circle.png");
  background-size: cover;

  border-radius: 10px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 50px 58px 50px 80px;
  padding-bottom: 0px;
}
.d-title {
  color: white;
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}
.d-sub-title {
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.downloadButton {
  cursor: pointer;
}

@media (max-width: 1100px) {
  .d-title{
    font-size: 40px;
  }
  .d-sub-container {
    padding: 53px 61px 0px 61px;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 650px) {
  .d-title{
    font-size: 28px;
  }
  .playstore-img{
    width: 114px;
  }
  .appstore-img{
    width: 101px;
  }
  .mbl-img{
    width: 279px;
  }
  .d-sub-container{
    padding: 30px 26px 0px 26px;
  }
}