.footer-container {
  width: 100%;
  background-color: #000;
  background-image: url("../../../public/footerBorders.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.footer-sub-container {
  width: 95%;
  height: 100%;
  padding: 50px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-top {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
}
.footer-top-one {
}
.footer-top-one-heading {
  color: white;
  font-size: 60px;
  font-weight: 600;
  margin: 0;
}
.footer-top-one-heading span {
  color: #aa0506;
}
.footer-top-one-sub-heading {
  color: white;
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0px;
}
.margin-horizontol {
  margin: 0px 10px;
}
a {
  cursor: pointer;
}
.footer-top-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-top-two-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  margin-bottom: 15px;
  text-decoration: none;
}
.footer-top-two-row-text {
  color: white;
  margin-left: 15px;
  font-size: 24px;
}
p {
  margin: 0;
}
.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}
.footer-bottom-text {
  color: #777;
  font-weight: 100;
}
.footer-bottom-links-container {
  display: flex;
  align-items: center;
}
.margin-left {
  margin-left: 15px;
}
@media (max-width: 878px) {
  .footer-top-two {
    margin-top: 30px;
  }
  .footer-bottom {
    flex-direction: column;
    margin-top: 50px;
  }
  .footer-bottom-links-container {
    margin-top: 30px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  .footer-bottom-text {
    font-size: 13px;
  }
  .footer-top-one-heading {
    font-size: 50px;
    font-weight: 500;
  }

  .footer-top-one-sub-heading {
    font-size: 20px;
    font-weight: 400;
  }
  .footer-top-two-row-text {
    font-size: 20px;
  }
  .footer-container {
    background-size: cover;
  }
}
@media (max-width: 735px) {
  .footer-top {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-top-one-heading {
    font-size: 35px;
  }
  .footer-top-one-sub-heading {
    font-size: 14px;
  }
  .footer-top-two-row-text {
    font-size: 16px;
  }
}
