.su-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10vh 0;
  overflow: hidden;
}
.su-main-heading {
  font-size: 48px;
  font-weight: bold;
}
.su-sub {
  width: 95%;
  display: flex;
  /* background-color: black; */
  justify-content: space-between;
  padding: 0px 5%;
  align-items: center;
}
.su-sub-left {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  width: 60%;
  align-self: stretch;
  margin-right: 10%;
  box-sizing: border-box;
}
.su-sub-left-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}
.su-form-image {
  background-color: #f8e2e2;
  object-position: 12px 12px;
  text-align: center;
  border-radius: 8px;
  height: max-content;
}
.su-form-image img{
  margin: 47px 67px 0px 67px;
}
.su-sub-left-payment-options-container {
  width: 100%;
  background-color: white;
  margin: 30px 0px;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 8.4px 8.4px 42px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.su-sub-payment-method-container img {
  height: 35px;
  width: 50px;
}
.su-link {
  color: black;
  cursor: pointer;
}
.su-sub-left-policy-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.su-sub-left-payment-options-subContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sub-sub-left-payment-price {
  color: red;
  font-weight: bold;
}
.su-sub-payment-method-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.su-sub-left-policy-subContainer {
  height: 34px;
  width: 34px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.su-sub-left-policy-box {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border-width: 0.8px;
  border-color: red;
  border: solid;
  margin-right: 5px;
}

.register-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700 !important;
}

.su-sub-left-policy-text {
  font-size: 14px;
  margin-left: 8px;
}
.su-sub-heading {
  font-size: 15;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  .su-sub-left {
    margin-right: 5%;
  }
  .su-main-heading {
    font-size: 32px;
    font-weight: 700;
  }
  .su-sub-left-policy-text {
    font-size: 12px;
    margin: 0;
  }
  .su-sub-left-policy-box {
    height: 16px;
    width: 16px;
  }
}
@media (max-width: 1130px) {
  .su-sub {
    flex-direction: column;
    gap: 100px;
  }
  .su-sub-left {
    width: 100%;
  }
  .su-form-image {
    width: 100%;
    /* height: 500px; */
    aspect-ratio: 2/1;
    object-fit: contain;
    /* background-color: #f8e2e2; */
    object-position: center 100px;
  }
  .su-sub-left-row-container {
    gap: 10px;
  }
  .su-sub-left-policy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  .su-sub-left-policy-container .Button-Reg {
    width: 100%;
  }
  .su-sub-payment-method-container img {
    width: 31px;
    height: 12px;
   }
}
@media (max-width: 700px) {
  .su-form-image{
    aspect-ratio: 4/5;
  }
  .su-sub-left-policy-box{
    height: 16px;
    width: 16px;
  }
  .su-main-heading {
    font-size: 22px;
    font-weight: 700;
  }
  .su-sub-heading{
    font-size: 12px;
  }
  .su-sub-payment-method-container img {
   width: 22px;
   height: 14px;
  }
  .su-sub-left-policy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .su-sub-left-policy-container .Button-Reg {
    width: 100%;
  }
  .su-sub-left-payment-options-subContainer p{
    font-size: 12px;
  }
  .su-form-image img{
    margin-top: 39px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 500px) {
  .su-sub-left-policy-box{
    height: 12px;
    width: 12px;
  }
  .su-form-image img{
    width: 80%;
    margin-top: 35px;
  }
}