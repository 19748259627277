.pf-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10vh 0;
  overflow: hidden;
}
.pf-sub {
  width: 95%;
  display: flex;
  /* background-color: black; */
  justify-content: center;
  /* align-items: center; */
  min-height: 60vh;
  box-sizing: border-box;
}
.pf-st-button {
  cursor: pointer;
  padding: 15px 40px;
  border-radius: 10px 0px 0px 10px;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
}
.pf-st-selected {
  background: linear-gradient(45deg, #f81d1e, #aa0506);
  color: white;
  font-weight: bold;
}
.pf-first-container {
  overflow: hidden;
  margin-right: -10px;
}
.pf-second-container {
  padding: 20px;
  background: linear-gradient(0deg, #f81d1e, #aa0506);
  min-width: 50%;
  width: 70%;
  border-radius: 10px;
}
.pf-pf-container {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;
}
.pf-pf-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pf-pf-heading {
  font-size: 30px;
}
.pf-pf-user-logo {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}
.pf-mb-mb-container {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
}
.pf-mb-mb-button {
  height: 250px;
  width: 45%;
  /* background-color: red; */
  border-radius: 10px;
  border: black 1px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pf-mb-mb-button:hover {
  background-color: red;
}
