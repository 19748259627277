.sub-heading-privacy {
  font-size: 20px;
  margin: 0px;
}
.privacy-terms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
}
.text-container-privacy {
  width: 95%;
  padding-top: 96px;
}
.whole-privacy-terms-text {
  font-size: 20px;
  font-weight: 500;
  color: #121212;
  margin-bottom: 64px;
  line-height: 30px;
}
.si-main-heading {
  margin-top: 12px;
}
.privacy-heading{
  font-size: 48px;
}

@media (max-width: 800px) {
  .privacy-heading{
    font-size: 40px;
  }
  .sub-heading-privacy {
    font-size: 16px;
  }
  .whole-privacy-terms-text {
    font-size: 16px;
    margin-bottom: 53px;
  }
  .text-container-privacy {
    padding-top: 80px;
  }
}

@media (max-width: 500px) {
  .privacy-heading{
    font-size: 30px;
  }
  .sub-heading-privacy {
    font-size: 12px;
  }
  .whole-privacy-terms-text {
    font-size: 12px;
    margin-bottom: 30px;
  }
  .text-container-privacy {
    padding-top: 50px;
  }
}
