.smservice-whole-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smservice-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 128px;
  margin-top: 32px;
}

.Button-Regs {
  margin-top: 64px;
  align-self: flex-start;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px;
  background: linear-gradient(45deg, #f81d1e, #aa0506);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.smservice-button {
  padding: 12px 19.5px;
  font-weight: 700;
}

.smservice-section-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.smservice-section-img {
  object-fit: cover;
  border-radius: 8px;
  object-position: center;
}

.smservice-heading-container {
  /* display: flex; */
  box-sizing: border-box;
  /* padding-top: 40px; */
  /* flex-direction: column; */
}
.smservice-heading-number {
  color: #aa0506;
  font-size: 58px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}
.smservice-heading-main {
  color: #000;
  font-size: 58px;
  font-weight: bold;
  margin-bottom: 0;
}
.smservice-heading-normal {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
}

.smservice-heading-desc {
  color: #000;
  font-size: 20px;

  font-weight: normal;
  margin-top: 10px;
  line-height: 32px;
  /* margin-bottom: 20px; */
}

@media (max-width: 1000px) {
  .smservice-container {
    gap: 107px;
  }
  .smservice-heading-main {
    font-size: 40px;
  }
  .smservice-heading-desc {
    font-size: 16px;
    line-height: 26px;
  }
  .smservice-heading-number {
    font-size: 40px;
  }
  .smservice-heading-normal {
    font-size: 20px;
  }
  .Button-Regs {
    margin-top: 53px;
    font-size: 15px;
    padding: 12px 16px;
  }
  .smservice-button {
    padding: 10px 11px;
  }
}
@media (max-width: 585px) {
  .smservice-container {
    gap: 80px;
  }
  .smservice-heading-main {
    font-size: 28px;
  }
  .smservice-heading-desc {
    font-size: 12px;
    line-height: 19px;
  }
  .smservice-heading-number {
    font-size: 28px;
  }
  .smservice-heading-normal {
    font-size: 14px;
  }
  .Button-Regs {
    margin-top: 30px;
    padding: 8px 12px;
    font-size: 12px;
  }
  .smservice-button {
    padding: 7px 4px;
  }
}
