.hero-container {
  display: flex;
  /* justify-content: center; */
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 85vh;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  width: 93.5%;
  height: 100%;
  background: linear-gradient(to bottom, #00000000, #000000cc);
  z-index: 1;
  border-radius: 10px;
}

.centered-content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* bottom: 150px; */
  flex-direction: column;
  z-index: 2;
}

.centered-content-title {
  font-size: 48px;
  font-weight: 700;
  width: 100%;
  color: white;
  text-align: center;
}

.centered-content-subtitle {
  font-size: 20px;
  font-weight: 500;
  color: white;
  margin-top: 20px;
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
}

.centered-content-button {
  margin-top: 40px;
}

.base-button {
  margin-top: 40px;
}

.image-container {
  width: 93.5%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 950px) {
  .base-button img {
    width: 29px;
  }
  .centered-content-title {
    font-size: 45px;
    width: 95%;
    /* scale: unset; */
  }
  .centered-content-subtitle {
    font-size: 18px;
  }
}
/* hero sec two */
.hero-container-two {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 690px) {
  .base-button img {
    width: 21px;
  }
  .centered-content-title {
    font-size: 32px;
    width: 92%;
    /* scale: unset; */
  }
  .centered-content-subtitle {
    font-size: 14px;
    margin: 20px;
  }
}
/* hero sec two */
.hero-container-two {
  display: flex;
  justify-content: center;
  width: 100%;
}

.hero-content-two {
  display: flex;
  margin-top: 100px;
  justify-content: space-around;
  align-items: center;
  width: 95%;
  gap: 20px !important;
}

.text-container-two {
  width: 46vw;
  max-width: 640px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: baseline;
}

.text-container-two span {
  font-size: 20px;
  font-weight: 400;
  /* margin-top: -30px; */
}

.text-container-two h2 {
  margin-top: 10px;
  margin-bottom: 12px;
  position: relative;
}

.text-container-two h2 span {
  position: absolute;
  top: 0;
  font-size: 18px;
  right: 0;
  font-weight: 700;
}

.button-container-two {
  margin-top: 20px;
}

.image-container-two {
  width: 100%;
  max-width: 640px;
  max-height: 540px;
}
.home-sec-three-content {
  width: 49vw;
  max-width: 640px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.image-container-two img {
  width: 40vw;
  aspect-ratio: 1/1;
  max-width: 640px;
  max-height: 540px;
}
.home-sec-three-button-container {
  display: flex;
  gap: 20px;
  color: white;
  margin-top: 24px;
}
.home-container-sec-logo {
  width: 58px;
  height: 58px;
}
@media (max-width: 1045px) {
  .home-sec-three-button-container {
    margin-top: 20px;
  }
  .home-sec-three-title {
    font-size: 40px !important;
    margin-bottom: 10px;
  }
  .text-container-two span {
    font-size: 18px;
  }
  .home-sec-three-images {
    aspect-ratio: 5/7 !important;
  }
}
@media (max-width: 887px) {
  .home-container-sec-logo {
    width: 49px;
    height: 49px;
  }
  .hero-content-two {
    flex-direction: column;
    gap: 40px;
  }
  .text-container-two {
    width: 100%;
    max-width: max-content;
  }
  .image-container-two img {
    width: 95vw;
    max-width: 100vw;
    max-height: 100vh;
  }
  .image-container-two {
    width: 90vw;
    max-width: max-content;
    max-height: max-content;
  }
  .home-sec-three-container {
    flex-direction: column;
    gap: 20px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100vw;
    overflow-x: hidden;
  }
  .home-sec-three-containers {
    flex-direction: column-reverse;
    gap: 40px;
    /* padding-left: 30px;
    padding-right: 30px; */
    width: 100vw;
    overflow-x: hidden;
  }
  .home-sec-three-content {
    width: 100%;
    max-width: 100%;
  }
  .home-sec-three-image {
    width: 95vw !important;
    margin-right: 0 !important;
    aspect-ratio: 708/452 !important;
  }
  .home-sec-three-images {
    width: 100% !important;
    margin-right: 0 !important;
    aspect-ratio: 9/5 !important;
  }
}
@media (max-width: 462px) {
  .home-container-sec-logo {
    width: 35px;
    height: 35px;
  }
  .text-container-two h2 span {
    margin-top: 0 !important;
    right: 9px !important;
  }
  .home-sec-three-button-container {
    margin-top: 15px;
  }
  .text-container-two h2 {
    font-size: 28px;
    margin-bottom: 7px;
  }
  .text-container-two span {
    font-size: 14px;
    margin-top: 7px;
  }
  .home-sec-three-image {
    width: 86vw !important;
  }
  .home-sec-three-images {
    width: 86vw !important;
  }
}

/* sec three */

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  width: 100%;
  max-width: 1344px;
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
}

.image-container img {
  width: 100%;
}

.text-container {
  width: 100%;
  max-width: 640px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
}

.text-container h2 {
  font-size: 48px;
  font-weight: 700;
  margin-top: 5px;
}

.text-container h2 span {
  font-size: 18px;
}

.text-container p {
  margin-top: -25px;
  font-size: 20px;
  font-weight: 400;
}

/* sec three */

.home-sec-three {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10vh 0;
}
.home-sec-three-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 95%;
}
.home-sec-three-containers {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 95%;
}
.home-sec-four {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  margin: 10vh 0;
  padding: 96px 64px;
  background-color: #1f1f1f;
}
.home-sec-three-image {
  width: 40vw;
  /* min-height: 656px; */
  /* max-height: 540px; */
  object-fit: cover;
  aspect-ratio: 640/538;
  border-radius: 8px;
  /* margin-right: 10vw; */
  /* background-color: red; */
}
.home-sec-three-images {
  width: 40vw;
  /* min-height: 656px; */
  /* max-height: 540px; */
  object-fit: cover;
  aspect-ratio: 7/9;
  border-radius: 8px;
  /* margin-right: 10vw; */
  /* background-color: red; */
}
.home-sec-three-img {
  width: 70px;
  height: 70px;
}

.home-sec-three-title {
  font-size: 48px;
  margin: 24px 0 12px 0;
  font-weight: 700;
  position: relative;
}
.home-sec-three-subTitless {
  font-size: 48px;
  margin: 24px 0 12px 0;
  font-weight: 700;
  position: relative;
}

.home-sec-three-subtitle {
  position: absolute;
  bottom: 22px;
  font-size: 32px;
}

.home-sec-three-paragraph {
  /* margin-top: -25px; */
  font-size: 20px;
  font-weight: 400;
}
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 30px;
  margin-top: 64px;
}
.home-sec-four-card {
  /* height: 322px; */
  flex-grow: 1;
  /* height: 100%; */
  width: 29vw;
  border-radius: 10px;
  background-color: #636e861f;
  /* justify-content: space-between; */
  align-items: center;
  padding: 32px;
  /* padding-bottom: 0; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.home-sec-four-container {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 50px; */
}
.home-sec-four-card-img {
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
}
.home-sec-four-card-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: start;
}
.home-sec-four-card-title {
  font-size: 22px;
  font-weight: 700;
  color: white;
  margin-bottom: 16px;
}
.home-sec-four-card-text {
  font-size: 16px;
  font-weight: 400;
  color: white;
  text-align: center;
  margin-bottom: 6px;
}
.home-sec-four-title {
  color: white;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin: 0;
}
.home-sec-four-subtitle {
  color: white;
  font-size: 48px;
  font-weight: 700;
  margin-top: 8px;
}

.home-sec-four-subtitle-text {
  color: "white";
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: #ffffffcc;
  width: 394;
}
.faq-row {
  font-size: 18px;
  color: #121212;
  line-height: 32px;
  font-weight: 400;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw {
  font-size: 16px;
}
.row-title-text {
  font-size: 22px;
  font-weight: 500;
  color: #121212;
}
.faq-title {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}
.faq-text {
  font-size: 20px;
  margin-top: 8px;
  font-weight: 400;
  color: #121212;
}

.home-sec-two-subtitle {
  position: absolute;
  bottom: 20px !important;
  right: 15px !important;
}

@media (max-width: 1121px) {
  .faq-title {
    font-size: 40px;
  }
  .faq-text {
    font-size: 18px;
    margin-top: 6px;
  }
  .row-title-text {
    font-size: 20px;
  }
  .faq-row {
    font-size: 16px;
  }
  .home-sec-three-img {
    width: 58px;
    height: 58px;
    margin-top: 50px;
  }
  .home-sec-four {
    padding-top: 80px;
    padding-left: 53px;
  }
  .home-sec-four-subtitle {
    font-size: 42px;
  }
  .home-sec-five-title {
    font-size: 45px;
  }

  .home-sec-four-card-img {
    margin-bottom: 15px;
    width: 60px;
    height: 60px;
  }
  .home-sec-four-card-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 14px;
  }
  .home-sec-four-card-text {
    font-size: 15px;
  }
  .home-sec-four-card {
    /* height:min-content; */
    padding: 22px;
    /* height: 285px; */
    /* padding-bottom: 0; */
  }
  .card-container {
    gap: 25px;
  }
}
@media (max-width: 935px) {
  .home-sec-four-card {
    height: 311px;
  }
}

@media (max-width: 840px) {
  .faq-title {
    font-size: 28px;
  }
  .faq-text {
    font-size: 14px;
    margin-top: 4px;
  }
  .row-title-text {
    font-size: 15px;
  }
  .faq-row {
    font-size: 12px;
  }
  .home-sec-three-img {
    width: 42px;
    height: 42px;
    margin-top: 30px;
  }
  .home-sec-three-wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .home-sec-three-title {
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .home-sec-three-subTitless {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .home-sec-three-paragraph {
    font-size: 16px;
    line-height: 26px;
  }
  .home-sec-two-para {
    font-size: 18px;
  }
  .home-sec-three-image {
    /* width: 100% !important; */
    /* min-height: 329px;
    height: 329px; */
    width: 95vw !important;
    aspect-ratio: 9/6;
    margin-right: 0;
  }

  .card-container {
    flex-direction: column;
    gap: 20px;
  }
  .home-sec-four-card-img {
    width: 50px;
    height: 50px;
    /* margin-left: 10px; */
    margin: 0px;
    margin-right: 20px;
  }
  .home-sec-four-card {
    width: 100%;
    flex-direction: row;
    height: fit-content;
    min-height: 30px;
    padding: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-sec-four-card-title {
    font-size: 18px;
    text-align: left;
    margin-bottom: 8px;
  }
  .home-sec-four-card-text {
    font-size: 14px;
    text-align: left;
    margin-bottom: 0;
  }
  .home-sec-four-card-text-container {
    align-items: flex-start;
    justify-content: center;
    margin: 10px;
    margin-left: 0px;
  }
  .home-sec-four-container {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .home-sec-five-containers {
    flex-direction: row;
    align-items: center;
  }
  .home-sec-four-title {
    font-size: 14px;
    line-height: 26px;
  }
  .home-sec-four-subtitle {
    font-size: 40px;
  }
  .home-sec-five-title {
    font-size: 45px;
  }
  .home-sec-four-subtitle-text {
    font-size: 14px;
  }
}
/* @media (min-width:588px){
  .home-sec-three-image{
    width: 90vw !important;
  }
} */
@media (max-width: 627px) {
  .home-sec-three-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-sec-three-containers {
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
  }
  .home-sec-three-image {
    aspect-ratio: 1/1 !important;
  }
  .home-sec-three-images {
    aspect-ratio: 335/237 !important;
    width: 100% !important;
  }
  .home-sec-three-title {
    font-size: 28px !important;
    margin-top: 14px;
    margin-bottom: 7px;
  }
  .home-sec-two-subtitle {
    position: absolute;
    top: -6px !important;
    right: 9px !important;
  }
  .home-sec-three-subTitless {
    font-size: 22px;
    margin-top: 14px;
    margin-bottom: 7px;
  }
  .home-sec-three-subtitle {
    font-size: 20px;
  }
  .home-sec-three-paragraph {
    font-size: 12px;
    line-height: 19px;
  }
  .home-sec-two-para {
    font-size: 14px;
  }
  .home-sec-four-card-title {
    font-size: 14px;
    margin-bottom: 0;
  }
  .home-sec-four {
    padding: 55px 20px;
  }
  .home-sec-four-card-text {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 4px;
  }
  .home-sec-four-card {
    align-items: start;
    /* height: max-content; */
    padding: 14px;
  }
  .home-sec-five-containers {
    flex-direction: column;
    align-items: flex-start;
  }
  .home-sec-four-card-img {
    margin: 0px;
    margin-right: 14px;
  }
  .home-sec-four-card-text-container {
    margin-left: 0;
  }
  .home-sec-four-subtitle {
    font-size: 28px;
  }
  .home-sec-five-title {
    font-size: 32px;
  }
  .home-sec-four-title {
    font-size: 12px;
    line-height: 19px;
  }
}
.home-sec-five {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10vh 0;
  overflow: scroll;
}
.home-sec-five-container {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  /* overflow: scroll; */
}
.home-sec-five-card-text-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
}
.home-sec-five-card-text-container p {
  font-size: 20px;
}
.home-sec-five-card {
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  width: 32%;
}
.home-sec-five-card-img {
  width: 100%;
  min-height: 200px;
}
.sec-five-card-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
  margin-top: 50px;
  min-width: 1024px;
  overflow: scroll;
  scrollbar-width: none;
}
.sec-five-card-container::-webkit-scrollbar {
  display: none;
}
.home-sec-four-container::-webkit-scrollbar {
  display: none;
}
.home-sec-five::-webkit-scrollbar {
  display: none;
}
.step {
  background-color: #f1f1f1;
  width: 70px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #f81d1e;
}
.points {
  background-color: #aa050608;
  border-radius: 50px;
  display: flex;
  margin-top: 10px;
  color: #f81d1e;
  padding: 12px 24px;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-bottom: 10px;
}
.sec-five-point-container {
  display: flex;
  flex-wrap: wrap;
}
.whatWeOfferContainer {
  /* width: 100%; */
  /* flex-grow: 1; */
  background-color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 44px;
  /* margin-right: 10px; */
}
.whatWeOfferContainer p {
  line-height: 50px;
  font-size: 24px;
  margin-top: 32px;
  color: white;
}
.whatWeOfferContainer div h1 {
  color: white;
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}
.sec7MainContainer {
  width: 95%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.s7maincontainer {
  gap: 16px;
}
.faq-text-container {
  min-width: 30%;
  margin-right: 20;
}
@media (max-width: 1095px) {
  .sub-container {
    padding: 40px !important;
  }
  .faq-text-container {
    margin-right: 0;
  }

  .sec-five-card-container {
    margin-bottom: 60px;
  }
  .sec7MainContainer img {
    width: 100%;
    position: relative;
    /* right: 5px; */
  }
  .sec7MainContainer {
    flex-direction: column;
    gap: 15px;
    /* align-items: center; */
  }
  .s7maincontainer {
    gap: 15px;
  }
  .whatWeOfferContainer div h1 {
    font-size: 40px;
    font-weight: 700;
  }
  .whatWeOfferContainer {
    padding: 36px;
  }
  .whatWeOfferContainer p {
    font-size: 20px;
    line-height: 42px;
    margin-top: 26px;
  }
  .whatWeOfferContainer svg {
    width: 67px;
  }
}
@media (max-width: 520px) {
  .home-sec-five-button {
    font-size: 12px !important;
  }
  .sub-container {
    padding: 20px !important;
  }
  .sec-five-card-container {
    margin-bottom: 35px;
  }
  .sec7MainContainer img {
    width: 100%;
    position: relative;
    /* right: 5px; */
  }
  .whatWeOfferContainer div h1 {
    font-size: 28px;
  }
  .whatWeOfferContainer {
    padding: 20px;
  }
  .whatWeOfferContainer p {
    font-size: 15px;
    line-height: 30px;
    margin-top: 20px;
  }
  .whatWeOfferContainer svg {
    width: 48px;
  }
}

@media (max-width: 1300px) {
  .home-sec-five-card-text-container p {
    font-size: 18px;
  }
  .points {
    font-size: 20px;
    padding: 10px 20px;
  }
}
@media (max-width: 950px) {
  .whatWeOfferContainer {
    margin-right: 0px;
    /* margin-bottom: 10px; */
  }
}
@media (max-width: 750px) {
  .points {
    font-size: 14px;
    padding: 7px 14px;
  }
}
@media (max-width: 600px) {
  .home-sec-five-card-text-container p {
    font-size: 14px;
  }
}
