.wir-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 37px;
}
.wir-main-item-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wir-banner {
  width: 100%;
  margin: 32px 0;
  min-height: 46vh;
  object-fit: cover;
  border-radius: 8px;
}
.whatisrileyBtn {
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

.para {
  font-weight: 400;
  font-size: 20px;
  color: #0f0e0e;
  margin-top: 8px;
}
.wir-main-item-container p {
  text-align: center;
}

.text-para {
  font-size: 20px;
  font-weight: 400px;
}

.si-main-heading {
  margin: 20px;
  margin-bottom: 0;
}
.Button-Regss {
  background: linear-gradient(45deg, #f81d1e, #aa0506);
  padding: 14px 20px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  border: 0;
}
.whatisrileys {
  font-size: 58px;
  margin-bottom: 12px;
}

.collaboration-collage-container {
  width: 100%;
  height: 80vh;
  gap: 5px;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 72px;
}
.wir-main-icon {
  width: 70px;
  height: 70px;
}
@media (max-width: 1050px) {
  .whatisrileys {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .Button-Regss {
    padding: 12px 16px;
    font-size: 15px;
  }
  .wir-main-icon {
    width: 58px;
    height: 58px;
  }
  .wir-banner {
    object-position: 75%;
    margin: 20px 0px;
  }
  .whatisrileyBtn {
    margin-top: 20px;
  }
  .text-para {
    font-size: 16px;
  }
  .para {
    font-size: 16px;
  }
  .collaboration-collage-container {
    margin-top: 40px;
  }
}

@media (max-width: 500px) {
  .wir-banner {
    min-height: 0px;
    aspect-ratio: 4/3;
    margin: 30px 0px;
  }
  .whatisrileyBtn {
    margin-top: 30px;
  }
  .whatisrileys {
    font-size: 28px;
    margin-bottom: 7px;
  }
  .Button-Regss {
    font-size: 12px !important;
    padding: 8px 12px;
  }
  .wir-main-icon {
    width: 42px;
    height: 42px;
  }
  .text-para {
    font-size: 12px;
  }
  .si-main-heading {
    margin: 7px;
  }
  .para {
    font-size: 12px;
  }
  .Button-Regss {
    padding: 8px 12px;
    font-size: 15px;
  }
  .collaboration-collage-container {
    margin-top: 30px;
  }
}
