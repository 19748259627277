.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10vh 0;
  overflow: hidden;
}
.main-container ::-webkit-scrollbar {
  display: none;
}
.sub-container {
  width: 95%;
  background-color: black;
  background-image: url("../../../public/leafBackground.png");
  background-size: cover;
  background-position: 0px -450px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 100px;
}
.title {
  color: white;
  font-size: 42px;
  font-weight: bold;
  margin: 0;
}
.sub-title {
  color: white;
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 830px) {
  .title{
    font-size: 35px;
  }
  .sub-title{
    font-size: 18px;
  }
}
@media (max-width: 700px){
  .sub-container {
    flex-direction: column;
    padding: 40px 53px;
    align-items: flex-start;
    gap: 20px;
  }
  
}
@media (max-width: 444px) {
  .su-register-btn{
    font-size: 12px !important;
  }
  .title{
    font-size: 25px;
  }
  .sub-title{
    font-size: 12px;
  }
  .sub-container{
    padding: 20px;
  }
}