.text-input-main-container {
  height: 56px;
  align-self: stretch;
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  width: 100%;
}
.text-input-heading {
  /* font-size: 20px; */
  background-color: white;
  align-self: flex-start;
  padding: 0px 10px;
  margin-top: -10px;
  margin-left: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12;
  font-weight: 500;
  position: absolute;
}
.text-input-text-input {
  height: 100%;
  width: 100%;
  border: 1px solid #12121250;
  border-radius: 8px;
  padding: 17px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
}
.text-input-text-input:focus {
  border: none;
  border: 1px solid #f81d1e;
  outline: none;
  border-radius: 8px;
  padding: 17px;
  box-sizing: border-box;
}
@media (max-width: 1150px) {
  .text-input-heading {
    font-size: 12px;
    margin-top: -5px;
    padding: 0px 5px;
    margin-left: 10px;
  }
}
@media (max-width: 786px) {
  .text-input-main-container {
    height: 40px;
  }
  .text-input-text-input {
    font-size: 12px;
    border-radius: 5px;
    padding: 10px;
  }
  .text-input-heading {
    font-size: 12px;
    margin-top: -7px;
    padding: 0px 5px;
    margin-left: 10px;
  }
}
