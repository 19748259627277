/* Sidebar.css */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* Initially hidden off the left side */
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: left 0.3s ease;
    padding-top: 60px;
    z-index: 101;
  }
  
  .sidebar.open {
    z-index: 101;
    left: 0; /* Show the sidebar when it's open */
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 8px 16px;
    text-align: left;
  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
    display: block;
  }
  
  .sidebar ul li a:hover {
    background-color: #575757;
  }
  
  .sidebar-toggle {
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: #333;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 101;
  }
  
  .sidebar-toggle:hover {
    background-color: #575757;
  }
  @media(min-width:786px){
    .SidebarMain {
        display: none;
    }
  }