/* contactus.css */

.page-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-send {
  width: 100%;
  border:0;
  height: 50px;
  background-image: linear-gradient(to right, #f81d1e, #aa0506);
  font-weight: 700;
  font-size: 16px;
  color: white;
  margin-top: 24px;
  border-radius: 8px;
  text-align: center;
}
.btn-send div{
  margin-left: auto;
  margin-right: auto;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 64px;
  margin-bottom: 48px;
}

.header-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.header-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
}

.icon-container {
  display: flex;
  gap: 16px;
}

.contactus-main {
  width: 95%;
  position: relative;
  margin-top: 32px;
  text-align: center;
  display: flex;
  gap: 288px;
  /* margin-bottom: 160px; */
  justify-content: center;
  flex-direction: column;
}

.image-contactus {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: red;
  max-height: 393px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}

.contactus-card-container {
  /* margin-top: 100px; */
  height: 100%;
  padding: 150px 7% 64px 7%;
  display: flex;
  flex-grow: 1;
  width: 80%;
  gap: 32px;
  flex-grow: 1;
  /* justify-content: space-evenly; */
  border-radius: 8px;
}
@media (max-width: 1720px) {
.contactus-card-container{
  padding: 140px 6.8% 64px 6.8%;
}
}


.contactus-card {
  border: 1px;
  padding: 32px 64px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  border-radius: 8px;
}

.contact-form-container {
  height: 406px;
  border: 1px;
  width: 83%;
  padding: 32px;
  padding-top: 45px;
  background-color: white;
  border-radius: 8px;
  gap: 25px;
  display: flex;
  flex-direction: column;
}
.position-form{
  position: absolute;
  bottom: 253px;
}

.form-row {
  display: flex;
  gap: 24px;
}
.icon-container-img-icon{
  width: 54px;
  height: 54px;
}

@media (max-width: 1178px) {
  .contactus-card {
    padding: 25px 3%;
  }
}

@media (max-width: 786px) {
  .contactus-main{
    gap: 228px;
    /* margin-bottom: 130px; */
  }
  .header-container{
    margin-top: 50px;
    margin-bottom: 40px;
  }
  .icon-container-img-icon{
    width: 45px;
    height: 45px;
  }
  .header-text{
    font-size: 18px;
    line-height: 27px;
  }
  .header-title{
    font-size: 32px;
    line-height: 48px;
  }
  .contactus-card-container {
    flex-direction: column;
    /* margin-top: 45px; */
    padding: 50px;
    padding-top: 130px;
    gap: 20px;
  }
  .contact-form-container {
    height: 346px;
    padding: 20px;
    padding-top: 30px;
    gap: 21px;
  }
  .position-form{
    bottom: 370px;   
  }
  .image-contactus {
    max-height: 330px;
  }
  .contactus-card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: row;
  }
}
@media (max-width: 500px) {
  .contactus-main{
    gap: 215px;
    /* margin-bottom: 90px; */
  }
  .header-container{
    margin-top: 35px;
    margin-bottom: 28px;
  }
  .icon-container-img-icon{
    width: 32px;
    height: 32px;
    margin-top: 8px;
  }
  .header-text{
    font-size: 12px;
    line-height: 18px;
  }
  .header-title{
    font-size: 20px;
    line-height: 30px;
  }
  .contactus-card-container{
    padding: 30px;
    padding-top: 114px;
  }
  .contactus-card{
    flex-direction: column;
  }
  .image-contactus {
    max-height: 237px;
  }
  /* .contact-form-container {
  } */
  .position-form{
    bottom: 457px;
  }
}
